import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {CartEvents} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/constants';

export class CartService {
  private readonly siteStore: SiteStore;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  public clearCart(cartId: string): Promise<void> {
    if (this.siteStore.isEditorMode() || this.siteStore.isPreviewMode() || !cartId) {
      return;
    }
    this.siteStore.pubSubManager.publish(CartEvents.Cleared, {cartId}, false);
  }
}
