import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/thankYouPage/settingsParams';
import {ILinkType} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {BIService} from './BIService';
import {OrderService} from './OrderService';
import {ILink} from '@wix/wixstores-client-core/dist/es/src/types';
import {isValidHttpUrl} from './isValidHttpUrl';
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client';

export type ShippingServiceConfig = {
  flowAPI: ControllerFlowAPI;
  siteStore: SiteStore;
  biService: BIService;
  orderService: OrderService;
};

export class NavigationService {
  private readonly siteStore: SiteStore;
  private readonly flowAPI: ControllerFlowAPI;
  private readonly biService: BIService;
  private readonly orderService: OrderService;

  constructor({flowAPI, siteStore, biService, orderService}: ShippingServiceConfig) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.biService = biService;
    this.orderService = orderService;
  }

  public async getContinueLinkHref(): Promise<string> {
    return (await this.getLink()).url;
  }

  public readonly onContinueClick = async (): Promise<void> => {
    const {concatenatedCatalogAppIds} = this.orderService.extractDataFromItemsForBI();

    this.biService.thankYouPageContinueShoppingClickedSf({
      orderId: this.orderService.orderGuid,
      cartId: this.orderService.cartId,
      checkoutId: this.orderService.checkoutId,
      concatenatedCatalogAppIds,
    });

    this.siteStore.navigateToLink(await this.getLink());
  };

  private async getLink(): Promise<ILink> {
    const url = getHeadlessUrl({
      query: this.siteStore.location.query,
      baseUrl: this.siteStore.location.baseUrl,
      navParams: {logicalName: NavigationType.ECOM_CONTINUE_BROWSING},
    });

    if (url) {
      return this.getExternalLink(url);
    }
    const externalContinueShoppingUrl = this.getContinueShoppingUrlFromAppSectionParams();
    if (externalContinueShoppingUrl && isValidHttpUrl(externalContinueShoppingUrl)) {
      return this.getExternalLink(externalContinueShoppingUrl);
    }

    const link = this.flowAPI.settings.get(settingsParams.THANK_YOU_PAGE_CONTINUE_SHOPPING_LINK_OBJECT);
    let linkResult: ILink;
    try {
      linkResult = await (link ? this.siteStore.getLink(link as ILinkType) : this.siteStore.getHomepageLink());
    } catch (e) {
      /* istanbul ignore next */ linkResult = await this.siteStore.getHomepageLink();
    }

    return linkResult;
  }

  private getExternalLink(url): Promise<ILink> {
    return this.siteStore.getLink({type: 'ExternalLink', target: '_top', url});
  }

  private getContinueShoppingUrlFromAppSectionParams() {
    const appSectionParams = this.siteStore.location.query.appSectionParams;
    return appSectionParams ? JSON.parse(appSectionParams).continueShoppingUrl : undefined;
  }
}
